<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="desserts"
			sort-by="calories"
			class="elevation-1"
		>
			<template #top>
				<v-toolbar
					flat
				>
					<v-toolbar-title>Advertisements</v-toolbar-title>
					<v-divider
						class="mx-4"
						inset
						vertical
					/>
					<v-spacer />
					<v-dialog
						v-model="dialog"
						max-width="500px"
					>
						<template #activator="{ on, attrs }">
							<v-btn
								color="primary"
								dark
								class="mb-2"
								v-bind="attrs"
								v-on="on"
							>
								New Item
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<v-container>
									<v-row>
										<v-col
											cols="12"
											sm="6"
											md="4"
										>
											<v-text-field
												v-model="editedItem.name"
												label="Dessert name"
											/>
										</v-col>
										<v-col
											cols="12"
											sm="6"
											md="4"
										>
											<v-text-field
												v-model="editedItem.calories"
												label="Calories"
											/>
										</v-col>
										<v-col
											cols="12"
											sm="6"
											md="4"
										>
											<v-text-field
												v-model="editedItem.fat"
												label="Fat (g)"
											/>
										</v-col>
										<v-col
											cols="12"
											sm="6"
											md="4"
										>
											<v-text-field
												v-model="editedItem.carbs"
												label="Carbs (g)"
											/>
										</v-col>
										<v-col
											cols="12"
											sm="6"
											md="4"
										>
											<v-text-field
												v-model="editedItem.protein"
												label="Protein (g)"
											/>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer />
								<v-btn
									color="blue darken-1"
									text
									@click="close"
								>
									Cancel
								</v-btn>
								<v-btn
									color="blue darken-1"
									text
									@click="save"
								>
									Save
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog
						v-model="dialogDelete"
						max-width="500px"
					>
						<v-card>
							<v-card-title class="text-h5">
								Are you sure you want to delete this item?
							</v-card-title>
							<v-card-actions>
								<v-spacer />
								<v-btn
									color="blue darken-1"
									text
									@click="closeDelete"
								>
									Cancel
								</v-btn>
								<v-btn
									color="blue darken-1"
									text
									@click="deleteItemConfirm"
								>
									OK
								</v-btn>
								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<!-- eslint-disable-next-line vue/valid-v-slot-->
			<template #item.actions="{ item }">
				<v-icon
					small
					class="mr-2"
					@click="editItem(item)"
				>
					mdi-pencil
				</v-icon>
				<v-icon
					small
					@click="deleteItem(item)"
				>
					mdi-delete
				</v-icon>
			</template>
			<template #no-data>
				<v-btn
					color="primary"
					@click="initialize"
				>
					Reset
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>
<script>
export default {
	name: "AdvertisementAdministrationTable",
	data: () => ({
		dialog: false,
		dialogDelete: false,
		headers: [
			{
				text: "Dessert (100g serving)",
				align: "start",
				sortable: false,
				value: "name",
			},
			{ text: "Calories", value: "calories" },
			{ text: "Fat (g)", value: "fat" },
			{ text: "Carbs (g)", value: "carbs" },
			{ text: "Protein (g)", value: "protein" },
			{ text: "Actions", value: "actions", sortable: false },
		],
		desserts: [],
		editedIndex: -1,
		editedItem: {
			name: "",
			calories: 0,
			fat: 0,
			carbs: 0,
			protein: 0,
		},
		defaultItem: {
			name: "",
			calories: 0,
			fat: 0,
			carbs: 0,
			protein: 0,
		},
	}),

	computed: {
		formTitle() {
			return this.editedIndex === -1 ? "New Item" : "Edit Item"
		},
	},

	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		},
	},

	created() {
		this.initialize()
	},

	methods: {
		initialize() {
			this.desserts = [
				{
					name: "Frozen Yogurt",
					calories: 159,
					fat: 6.0,
					carbs: 24,
					protein: 4.0,
				},
				{
					name: "Ice cream sandwich",
					calories: 237,
					fat: 9.0,
					carbs: 37,
					protein: 4.3,
				},
			]
		},

		editItem(item) {
			this.editedIndex = this.desserts.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true
		},

		deleteItem(item) {
			this.editedIndex = this.desserts.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},

		deleteItemConfirm() {
			this.desserts.splice(this.editedIndex, 1)
			this.closeDelete()
		},

		close() {
			this.dialog = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		save() {
			if (this.editedIndex > -1) {
				Object.assign(this.desserts[this.editedIndex], this.editedItem)
			} else {
				this.desserts.push(this.editedItem)
			}
			this.close()
		},
	},
}
</script>
